.page {
  font-family: Arial, Helvetica, sans-serif;
}

#root {
  background-color: white;
  font-family: 'Blender Pro';
}

header {
  font-size: 16px;
}

div.menu {
  height: 50px;
}
